import { Component, Injectable, OnInit, Output } from "@angular/core";
import { MainService } from "../services/backend/main.service";
import { Router } from "@angular/router";
import { LoadService } from "../services/load.service";
import { Translate } from "../models/translate";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../services/backend/user.service";
import { QuestionService } from "../services/backend/question.service";
import { Clusters } from "../models/clusters";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PolicyComponent } from "./policy/policy.component";
import { ConditionsComponent } from "./conditions/conditions.component";
import { InstructionsComponent } from "./instructions/instructions.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { LanguageComponent } from "./language/language.component";
import { AuthService } from "../services/auth.service";
import { LeaveConfirmComponent } from "./leave-confirm/leave-confirm.component";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.less"],
})
export class QuestionsComponent implements OnInit {
  questions: Question[] = [];
  curQuestion: Question;
  answers: Answer[] = [];
  showImg = false;
  startTime: number;
  uid = 0;
  PNumber: number;
  CurPart = 0;
  MainQuestionId = 0;
  participants: Participant[] = [];
  menuOpened = false;
  isAdmin = false;
  testAccess: string;
  startQuestionId: number = 0;
  menuItemEnum = MenuItem;
  constructor(
    private ms: MainService,
    private userSerive: UserService,
    private qs: QuestionService,
    private as: AuthService,
    private router: Router,
    private ls: LoadService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.userSerive.GetUserId().subscribe((id: number) => {
      this.uid = id;
    });
    this.userSerive.CheckUser().subscribe((response) => {
      if (response) {
        this.isAdmin = true;
      }
    });
    this.userSerive.CheckTestAccess().subscribe((data) => {
      this.testAccess = data["TestAccess"];
      switch (this.testAccess) {
        case "2": {
          this.userSerive.GetUserCurQuestion().subscribe((id) => {
            this.startQuestionId = id;
            this.qs
              .GetQuestions(Number(this.startQuestionId["CurQuestion"]))
              .subscribe((data) => {
                data.forEach((x) => {
                  x.LastQuestion = Boolean(x.LastQuestion);
                });
                this.questions = data;
                this.questions.forEach(question => {
                  if (question.Number == '3') {
                    question.LastQuestion = true;
                  }
                });
                console.log(this.questions);
                this.nextQuestion(this.questions[0].Id);
              });
          });
          break;
        }
        case "1": {
          let lastQuestion: boolean = false;
          for (let i: number = 1; i < 4; i++) {
            if (i == 3) {
              lastQuestion = false;
            }
            this.questions.push({
              Id: i,
              Number: i.toString(),
              Question: {en: `Test Question № ${i}`, ru: `Тестовый вопрос № ${i}`},
              QuestionRu: `Тестовый вопрос № ${i}`,
              QuestionEng: `Test Question № ${i}`,
              Type: 0,
              NextQuestionId: i + 1,
              LastQuestion: lastQuestion,
              Variants: [
                {
                  Id: 1,
                  QuestionId: i,
                  Number: 1,
                  Variant: {en: "answer № 1", ru: "ответ № 1"},
                  VariantEng: "answer № 1",
                  VariantRu: "ответ № 1",
                  NextQuestionId: i + 1,
                  Situation: 0,
                },
                {
                  Id: 2,
                  QuestionId: i,
                  Number: 2,
                  Variant: {en: "answer № 2", ru: "ответ № 2"},
                  VariantEng: "answer № 2",
                  VariantRu: "ответ № 2",
                  NextQuestionId: i + 1,
                  Situation: 0,
                },
                {
                  Id: 3,
                  QuestionId: i,
                  Number: 3,
                  Variant: {en: "answer № 3", ru: "ответ № 3"},
                  VariantEng: "answer № 3",
                  VariantRu: "ответ № 3",
                  NextQuestionId: i + 1,
                  Situation: 0,
                },
              ],
            });
          }
          this.questions.forEach(element => {
            element.LastQuestion = Boolean(element.LastQuestion);
          });
          this.nextQuestion(this.questions[0].Id);
          break;
        }
        default: {
          this.userSerive.GetUserCurQuestion().subscribe((id) => {
            this.startQuestionId = id;
            this.qs
              .GetQuestions(Number(this.startQuestionId["CurQuestion"]))
              .subscribe((data) => {
                data.forEach((x) => {
                  x.LastQuestion = Boolean(x.LastQuestion);
                });
                this.questions = data;
                console.log(this.questions);
                this.nextQuestion(this.questions[0].Id);
              });
          });
          break;
        }
      }
    });
  }

  Answer(variant: Variant) {
    if (this.curQuestion.Type == 3) {
      this.PNumber = Number(variant.Number);
      for (let i = 0; i < this.PNumber; i++) {
        this.participants.push(new Participant(i, this.uid));
      }
      this.CurPart = 0;
      this.curQuestion.Type = 4;
      this.curQuestion.Question = "PARTICIPANT_NAME";
      this.curQuestion.Variants = [];
    } else {
      this.answers.push({
        UserId: this.uid,
        QuestionId: this.curQuestion.Id,
        Answer: variant.Id.toString(),
        PartId:
          this.MainQuestionId > 0 ? this.participants[this.CurPart].Id : 0,
        Speed: new Date().getTime() - this.startTime,
      });
      if (this.curQuestion.LastQuestion) {
        this.saveAnswers();
      } else {
        if (variant.NextQuestionId > 0) {
          this.nextQuestion(variant.NextQuestionId);
        } else {
          if (this.CurPart == this.participants.length - 1) {
            let m = this.questions.find((x) => x.Id == this.MainQuestionId);
            if (m.LastQuestion) {
              this.saveAnswers();
            } else {
              this.MainQuestionId = 0;
              this.nextQuestion(m.NextQuestionId);
            }
          } else {
            this.CurPart++;
            this.nextQuestion(this.MainQuestionId);
          }
        }
      }
    }
  }

  AnswerT(text) {
    var t = text.value;
    if (this.curQuestion.Type == 4) {
      this.participants[this.CurPart].PartName = t;
      this.qs
        .SaveParticipant(this.participants[this.CurPart])
        .subscribe((participantId) => {
          this.participants[this.CurPart].Id = participantId;
        });
      if (this.CurPart == this.participants.length - 1) {
        this.CurPart = 0;
        this.nextQuestion(this.curQuestion.NextQuestionId);
      } else {
        this.CurPart++;
        this.curQuestion.Question = "Name of participant ";
      }
    } else {
      this.answers.push({
        UserId: this.uid,
        QuestionId: this.curQuestion.Id,
        Answer: t,
        PartId:
          this.MainQuestionId > 0 ? this.participants[this.CurPart].Id : 0,
        Speed: new Date().getTime() - this.startTime,
      });
      if (this.curQuestion.LastQuestion) {
        this.saveAnswers();
      } else {
        if (this.curQuestion.NextQuestionId > 0) {
          this.nextQuestion(this.curQuestion.NextQuestionId);
        } else {
          if (this.CurPart == this.participants.length - 1) {
            let m = this.questions.find((x) => x.Id == this.MainQuestionId);
            if (m.LastQuestion) {
              this.saveAnswers();
            } else {
              this.MainQuestionId = 0;
              this.nextQuestion(m.NextQuestionId);
            }
          } else {
            this.CurPart++;
            this.nextQuestion(this.MainQuestionId);
          }
        }
      }
    }
    text.value = "";
  }

  nextQuestion(id) {
    let t = this.questions.find((x) => x.Id == id);

    if (Number(t.Type) == 2) {
      if (this.MainQuestionId == 0) {
        this.CurPart = 0;
      }

      this.MainQuestionId = Number(t.Id);
    }
    if (t && this.CurPart == 0 && t.Image) {
      this.showImg = true;
    } else {
      this.showImg = false;
      this.startTime = new Date().getTime();
    }
    this.curQuestion = t;
    if (this.showImg) {
      var vm = this;
      setTimeout(function () {
        vm.showImg = false;
        this.startTime = new Date().getTime();
      }, 4000);
    }
  }

  saveAnswers() {
    if (this.testAccess == "1" || this.testAccess == "2") {
      console.log("Test ended");
    } else {
      this.ls.showLoad = true;
      if (Number(this.startQuestionId["CurQuestion"]) != 1) {
        this.qs.SaveAnswers(this.answers, 1).subscribe((data) => {
          this.router.navigate(["/conclusions"]);
        });
      } else {
        this.qs.SaveAnswers(this.answers).subscribe((data) => {
          this.router.navigate(["/conclusions"]);
        });
      }
    }
  }

  onMenuItemClick(itemName: MenuItem) {
    this.menuOpened = false;
    switch (itemName) {
      case MenuItem.Policy: {
        this.modalService.open(PolicyComponent, { size: "lg" });
        return;
      }
      case MenuItem.Conditions: {
        this.modalService.open(ConditionsComponent, { size: "lg" });
        return;
      }
      case MenuItem.Instructions: {
        this.modalService.open(InstructionsComponent, { size: "lg" });
        return;
      }
      case MenuItem.ContactForm: {
        this.modalService.open(ContactFormComponent, { size: "lg" });
        return;
      }
    }
  }

  gotoAdmin() {
    if (this.isAdmin) {
      this.router.navigate(["/admin"]);
    }
  }

  changeLang() {
    this.modalService.open(LanguageComponent, { size: "lg" });
  }

  exit() {
    if (
      this.curQuestion.Id == 1 ||
      (this.curQuestion.Id > 3 && this.curQuestion.Id < 13)
    ) {
      this.as.exit();
      this.router.navigate([""]);
      return;
    }
    let modal = this.modalService.open(LeaveConfirmComponent, { size: "lg" });
    modal.componentInstance.answers = this.answers;
    modal.componentInstance.curQuestion = this.curQuestion.Id;
  }
}

export class Question {
  Id: number;
  Number?: string;
  Question?: Translate | string;
  QuestionRu: string;
  QuestionEng: string;
  Cluster?: Clusters;
  Type: number;
  Image?: string;
  NextQuestionId: number;
  LastQuestion: boolean;

  Variants: Variant[];
}
export interface Variant {
  Id: number;
  QuestionId: number;
  Number: number;
  Variant?: Translate | string;
  Situation: number | string;
  NextQuestionId: number;
  VariantEng: string;
  VariantRu: string;
}

export class Answer {
  Id?: number = 0;
  UserId: number;
  QuestionId: number;
  Answer: string;
  PartId: number;
  Speed: number;
}

export class Participant {
  constructor(id, uid) {
    this.Id = id;
    this.UserId = uid;
  }
  Id: number;
  UserId: number;
  PartName: number;
}

export enum MenuItem {
  Policy = "policy",
  Conditions = "conditions",
  Instructions = "instructions",
  ContactForm = "contact-form",
  Quit = "quit",
}
