import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//------Guard------
import { AuthGuard } from "./services/auth.guard";
import { AdminGuard } from "./services/admin.guard";

//------Components------
import { RegFormComponent } from "./reg-form/reg-form.component";
import { QuestionsComponent } from "./questions/questions.component";
import { FeedbackFormComponent } from "./feedback-form/feedback-form.component";
import { ConclusionsComponent } from "./conclusions/conclusions.component";
import { AdminComponent } from "./admin/admin.component";
import { AdminQuestionsComponent } from "./admin/admin-questions/admin-questions.component";
import { AdminUsersComponent } from "./admin/admin-users/admin-users.component";
import { AdminStatisticsComponent } from "./admin/admin-statistics/admin-statistics.component";
import { AdminGraphicsComponent } from "./admin/admin-graphics/admin-graphics.component";
import { AdminDataComponent } from "./admin/admin-data/admin-data.component";
import { AdminConclusionsComponent } from "./admin/admin-conclusions/admin-conclusions.component";
import { InfoComponent } from "./info/info.component";

const routes: Routes = [
  { path: "", component: RegFormComponent, pathMatch: "full" },
  //{ path: "info", component: InfoComponent},
  {
    path: "questions",
    component: QuestionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "conclusions",
    component: ConclusionsComponent,
    //canActivate: [AuthGuard],
  },
  {
    path: "feedback",
    component: FeedbackFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "questions",
        component: AdminQuestionsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "users",
        component: AdminUsersComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "statistics",
        component: AdminStatisticsComponent,
        canActivate: [AdminGuard],
      },
      { path: "graphics", component: AdminGraphicsComponent },
      { path: "data", component: AdminDataComponent },
      { path: "conclusions", component: AdminConclusionsComponent },
      {
        path: "",
        redirectTo: "questions",
        pathMatch: "full",
        canActivate: [AdminGuard],
      },
    ],
    canActivate: [AdminGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
