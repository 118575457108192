import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-create-question",
  templateUrl: "./create-question.component.html",
})
export class CreateQuestionComponent {
  constructor(public modal: NgbActiveModal) {}
  onClose(question: string): void {
    this.modal.close(question);
  }
}
