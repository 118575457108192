import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Participant, Variant } from 'src/app/questions/questions.component';
import { AlertService } from 'src/app/services/alert/alert.service';
import { QuestionService } from 'src/app/services/backend/question.service';
import { AnswerData } from '../admin-data.component';

@Component({
  selector: 'app-change-answer',
  templateUrl: './change-answer.component.html',
  styleUrls: ['./change-answer.component.css']
})
export class ChangeAnswerComponent implements OnInit {
  @Input() answer: any;
  variantFormControl: FormControl = new FormControl();
  variants: Variant[];
  participant: Participant;
  submitted: boolean = false;
  questionTypeInput: boolean = false;
  questionTypeSelect: boolean = false;
  questionTypePart: boolean = false;

  constructor(public modal: NgbActiveModal, public fb: FormBuilder, private alertService: AlertService, private questionService: QuestionService) { }

  ngOnInit(): void {
    switch (this.answer.Type) {
      case '0': {
        this.questionTypeSelect = true;
        this.questionService.GetQuestionVariants(this.answer.QuestionId).subscribe((data) => {
          if (data) {
            this.variants = data;
          }
        });
        break;
      }
      case '1': {
        this.questionTypeInput = true;
        this.variantFormControl.setValue(this.answer.Answer);
        break;
      }
      case '2': {
        this.questionTypePart = true;
        this.questionService.GetParticipantById(this.answer.PartId).subscribe((data) => {
          if (data) {
            this.participant = data;
          }
        })
        this.questionService.GetQuestionVariants(this.answer.QuestionId).subscribe((data) => {
          if (data) {
            this.variants = data;
          }
        });
        break;
      }
    }
  }

}
