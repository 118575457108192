import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/services/auth.service";
import { QuestionService } from "src/app/services/backend/question.service";
import { UserService } from "src/app/services/backend/user.service";
import { Answer, Question } from "../questions.component";

@Component({
  selector: "leave-confirm",
  templateUrl: "./leave-confirm.component.html",
  styleUrls: ["./leave-confirm.component.css"],
})
export class LeaveConfirmComponent implements OnInit {
  @Input() answers: Answer[];
  @Input() curQuestion: number;
  constructor(
    public modal: NgbActiveModal,
    public authService: AuthService,
    public router: Router,
    public questionService: QuestionService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
  }

  saveExit() {
    this.questionService.SaveAnswers(this.answers, 0).subscribe((response) => {
    });
    this.userService
      .ChangeUserCurQuestion(this.curQuestion)
      .subscribe((response) => {
        if (response) {
          this.modal.dismiss();
          this.authService.exit();
          this.router.navigate([""]);
        }
      });
  }

  exit() {
    this.modal.dismiss();
    this.authService.exit();
    this.router.navigate([""]);
  }
}
