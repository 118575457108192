import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MainService, User } from "../services/backend/main.service";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationFormComponent } from "./application-form/application-form.component";
import { AuthService } from "../services/auth.service";
import { AlertService } from "../services/alert/alert.service";
import { AttemptFormComponent } from "./attempt-form/attempt-form.component";
import { UserService } from "../services/backend/user.service";
import { LoadService } from "../services/load.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "reg-form",
  templateUrl: "./reg-form.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./reg-form.component.less"],
})
export class RegFormComponent implements OnInit {
  enterForm: FormGroup;
  submitted = false;
  touched = false;
  accessDenied = false;
  alertMessage = "User not found!";
  public data: User = new User();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadService,
    private us: UserService,
    private modal: NgbModal,
    private alertService: AlertService,
    public translate: TranslateService
  ) {}
  ngOnInit() {
    this.translate.use("en");
    this.translate.currentLang = "en";
    if (sessionStorage.getItem("userToken")) {
      this.router.navigate(["/questions"]);
    }
    this.enterForm = this.fb.group({
      Code: [
        null,
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
  }

  changeLang() {
    if (this.translate.currentLang == "en") {
      this.translate.use("ru");
    } else {
      this.translate.use("en");
    }
  }

  sendApp() {
    this.modal.open(ApplicationFormComponent);
  }

  get fval() {
    return this.enterForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.enterForm.invalid) {
      return;
    }
    const subscription = this.us.GetUser(this.enterForm.value).subscribe(
      (data) => {
        if (data[0]) {
          if (data[1] > 0) {
            this.authService.setToken(data[0]);
            this.router.navigate(["/questions"]);
          } else {
            this.modal.open(AttemptFormComponent, { centered: true });
          }
        } else {
          this.submitted = false;
          this.enterForm.reset();
          if (this.translate.currentLang == "ru") {
            this.alertMessage = "Пользователь не найден!";
          } else {
            this.alertMessage = "User not found!";
          }
          this.alertService.show(this.alertMessage, {
            classname: "bg-danger text-light unf",
            delay: 5000,
          });
        }
        this.loadingService.removeSubscription(subscription);
      },
      (error: HttpErrorResponse) => {
        console.log(error.error.text);
        let t = JSON.parse(
          error.error.text.match(/{.+}$/gi)
            ? error.error.text.match(/{.+}$/gi)[0]
            : false
        );
        if (t) {
          t.IsAdmin = Boolean(Number(t.IsAdmin));
          localStorage.setItem("user", JSON.stringify(t));
          this.router.navigate(["/questions"]);
        } else {
          this.accessDenied = true;
        }
      }
    );
  }
}
