import { NgModule } from "@angular/core";
import { MatTabsModule } from '@angular/material/tabs';

const materialModules = [
    MatTabsModule
];

@NgModule({
    imports: materialModules,
    exports: materialModules
})

export class MaterialModule { }