import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Message, MainService } from "src/app/services/backend/main.service";
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: "app-attempt-form",
  templateUrl: "./attempt-form.component.html",
  styleUrls: ["./attempt-form.component.css"],
})
export class AttemptFormComponent implements OnInit {
  public appForm: FormGroup;
  private rxAlive: boolean = true;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private service: MainService
  ) {
    this.appForm = fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      message: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  public send() {
    if (this.appForm.invalid) {
      const { controls } = this.appForm;
      Object.keys(controls).forEach((key) => {
        if (controls[key].invalid) {
          controls[key].markAsDirty();
        }
      });
      return;
    }

    const formValue = this.appForm.getRawValue() as Message;

    this.service
      .SendMessage(formValue)
      .pipe(takeWhile(() => this.rxAlive))
      .subscribe(() => {
        this.modal.close();
      });
  }
}
