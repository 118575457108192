import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  NgbActiveModal,
  NgbModalModule,
  NgbModule,
  NgbRatingModule,
} from "@ng-bootstrap/ng-bootstrap";
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ProgTranslatePipe } from "./services/translate.pipe";
import { TagsComponent } from "./components/tags/tags.component";
import { GoogleChartsModule, ScriptLoaderService } from "angular-google-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";

//------Routing------
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./services/auth.guard";
import { AdminGuard } from "./services/admin.guard";
//import { LeaveGuard } from "./services/leave.guard";

//------Services------
import { MainService } from "./services/backend/main.service";
import { QuestionService } from "./services/backend/question.service";
import { UserService } from "./services/backend/user.service";
import { AuthService } from "./services/auth.service";
import { LoadService } from "./services/load.service";
import { AlertService } from "./services/alert/alert.service";

//------Interceptors------
import { AuthInterceptor } from "./services/auth.interceptor";

//------Components------
import { ApplicationFormComponent } from "./reg-form/application-form/application-form.component";
import { LoadComponent } from "./load/load.component";
import { AppComponent } from "./app.component";
import { RegFormComponent } from "./reg-form/reg-form.component";
import { QuestionsComponent } from "./questions/questions.component";
import { AlertContainerComponent } from './services/alert/alert-container.component';
import { AttemptFormComponent } from './reg-form/attempt-form/attempt-form.component';
import { ConclusionsComponent } from './conclusions/conclusions.component';
import { AdminComponent } from './admin/admin.component';
import { AdminQuestionsComponent } from './admin/admin-questions/admin-questions.component';
import { PolicyComponent } from './questions/policy/policy.component';
import { ConditionsComponent } from './questions/conditions/conditions.component';
import { InstructionsComponent } from './questions/instructions/instructions.component';
import { ContactFormComponent } from './questions/contact-form/contact-form.component';
import { CreateQuestionComponent } from "./admin/admin-questions/create-question/create-question.component";
import { EditQuestionComponent } from "./admin/admin-questions/edit-question/edit-question.component";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { UserChangeComponent } from './admin/admin-users/userchange/userchange.component';
import { UserAddComponent } from './admin/admin-users/user-add/user-add.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminStatisticsComponent } from './admin/admin-statistics/admin-statistics.component';
import { AdminGraphicsComponent } from './admin/admin-graphics/admin-graphics.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminDataComponent } from './admin/admin-data/admin-data.component';
import { LanguageComponent } from './questions/language/language.component';
import { ChangeAnswerComponent } from './admin/admin-data/change-answer/change-answer.component';
import { AdminConclusionsComponent } from './admin/admin-conclusions/admin-conclusions.component';
import { CreateConclusionComponent } from './admin/admin-conclusions/create-conclusion/create-conclusion.component';
import { EditConclusionComponent } from './admin/admin-conclusions/edit-conclusion/edit-conclusion.component';
import { AddVariantComponent } from './admin/admin-conclusions/edit-conclusion/add-variant/add-variant.component';
import { GroupAddComponent } from './admin/admin-users/group-add/group-add.component';
import { FeedbackFormComponent } from "./feedback-form/feedback-form.component";
import { EditGroupComponent } from './admin/admin-users/edit-group/edit-group.component';
import { LeaveConfirmComponent } from './questions/leave-confirm/leave-confirm.component';
import { InfoComponent } from './info/info.component';
import { MaterialModule } from "./material.module";

@NgModule({
  declarations: [
    AppComponent,
    RegFormComponent,
    QuestionsComponent,
    LoadComponent,
    ApplicationFormComponent,
    ProgTranslatePipe,
    FeedbackFormComponent,
    TagsComponent,
    AlertContainerComponent,
    AttemptFormComponent,
    ConclusionsComponent,
    AdminComponent,
    AdminQuestionsComponent,
    PolicyComponent,
    ConditionsComponent,
    InstructionsComponent,
    ContactFormComponent,
    CreateQuestionComponent,
    EditQuestionComponent,
    FileUploaderComponent,
    UserChangeComponent,
    UserAddComponent,
    AdminUsersComponent,
    AdminStatisticsComponent,
    AdminGraphicsComponent,
    AdminDataComponent,
    LanguageComponent,
    ChangeAnswerComponent,
    AdminConclusionsComponent,
    CreateConclusionComponent,
    EditConclusionComponent,
    AddVariantComponent,
    GroupAddComponent,
    EditGroupComponent,
    LeaveConfirmComponent,
    InfoComponent,
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserModule,
    NgbModalModule,
    NgbRatingModule,
    GoogleChartsModule,
    NgbModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserModule, 
    FormsModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    MaterialModule,
  ],
  providers: [
    FormBuilder,
    HttpClient,
    AuthGuard,
    AdminGuard,
    //LeaveGuard,
    MainService,
    QuestionService,
    UserService,
    LoadService,
    AuthService,
    AlertService,
    TranslatePipe,
    ProgTranslatePipe,
    NgbActiveModal,
    ScriptLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
