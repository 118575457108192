import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/backend/user.service';

@Component({
  selector: 'group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.less']
})
export class GroupAddComponent implements OnInit {
  addGroupForm: FormGroup;
  submitted: boolean = false;
  constructor( public modal: NgbActiveModal, private fb: FormBuilder, private userService: UserService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.addGroupForm = this.fb.group({
      GroupName: [null, Validators.required],
      GroupDescription: [null]
    });
  }

  get fval() {
    return this.addGroupForm.controls;
  }

  createGroup() {
    this.submitted = true;
    if (this.addGroupForm.invalid) {
      return;
    }
    this.userService.AddGroup(this.addGroupForm.value).subscribe((response) => {
      if (response) {
        this.modal.dismiss();
        this.alertService.show("Группа создана!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    })
  }
}
