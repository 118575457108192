import {Component, TemplateRef} from '@angular/core';

import {AlertService} from './alert.service';


@Component({
  selector: 'alerts',
  template: `
    <ngb-toast
      *ngFor="let alert of alertService.alerts"
      [class]="alert.classname"
      [autohide]="true"
      [delay]="alert.delay || 5000"
      (hide)="alertService.remove(alert)"
    >
      <ng-template [ngIf]="isTemplate(alert)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="alert.textOrTpl"></ng-template>
      </ng-template>
      <ng-template #text>{{ alert.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: {'[class.ngb-toasts]': 'true'},
  styles: [`
    .unf {
      text-align: center;
      width: auto;
      height: 60px;
      font-size: 24px;
      font-family: Circe ExtraLight;
    }
    .us-dels {
      text-align: center;
      width: 270px;
      height: 60px;
      font-size: 22px;
      font-family: Circe ExtraLight;
    }
    .us-delb {
      text-align: center;
      width: 500px;
      height: 125px;
      font-size: 22px;
      font-family: Circe ExtraLight;
    }
    .ans-type {
      text-align: center;
      width: 700px;
      height: 125px;
      font-size: 22px;
      font-family: Circe ExtraLight;
    }
  `]
})

export class AlertContainerComponent {
  constructor(public alertService: AlertService) {}

  isTemplate(alert) { return alert.textOrTpl instanceof TemplateRef; }
}