import { Component, OnInit, forwardRef } from "@angular/core";
import { Validators, AbstractControl, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsComponent),
      multi: true,
    },
  ],
})
export class TagsComponent implements OnInit {
  constructor() {}

  tags: string[] = [];
  disabled = false;

  ngOnInit(): void {}

  setCursor(span: HTMLSpanElement, { target }) {
    if (!target.closest("span")) {
      span.focus();
    }
  }

  addTag(e, span, index?) {
    if (
      (e.code == "KeyV" && (e.ctrlKey || e.metaKey)) ||
      e.code == "Enter" ||
      e.code == "NumpadEnter"
    ) {
      e.preventDefault();
    }
    const edit = index == 0 || !!index;
    
    if (e.key != "Enter") {
      return;
    }

    if (edit) {
      span = span.querySelectorAll("span:not(.placeholder)")[index];
    }
    const text = span.innerText.replace(/\s/g, "") as string;
    if (
      !text ||
      !text.match(/^[a-zA-Z0-9]+[a-zA-Z.1-9]*@[a-zA-Z0-9]+[a-zA-Z.1-9]*/)
    ) {
      span.classList.add("text-danger");
      return;
    }
    span.classList.remove("text-danger");
    if (edit) {
      this.tags[index] = span.innerText;
      this.updateValue(this.tags);
      return;
    }
    this.tags.push(span.innerText);
    span.innerText = "";
    this.updateValue(this.tags);
  }

  remove(e, container, index, toFocus) {
    if (e.key != "Backspace" && e.key != "Delete") {
      return;
    }
    const span = container.querySelectorAll("span:not(.placeholder)")[index];
    if (!span.innerText) {
      this.tags.splice(index, 1);
      toFocus.focus();
    }
  }

  /**
   * Value accessor
   */

  private onChange = (value: any) => {};
  private onTouched = () => {};

  /*
    Value Accessor
  */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(out: string[]) {
    this.tags = out || [];
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateValue(value: string[]) {
    this.tags = value;
    this.onChange(value);
    this.onTouched();
  }
}
