import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/backend/user.service';
import { AdminUsersComponent } from '../admin-users.component';

@Component({
  selector: 'userchange',
  templateUrl: './userchange.component.html',
  styleUrls: ['./userchange.component.less']
})
export class UserChangeComponent implements OnInit {
  @Input() user: any;
  changeUserForm: FormGroup;
  submitted = false;
  reload = false;

  constructor(public modal: NgbActiveModal, public fb: FormBuilder, private alertService: AlertService, private us: UserService) { }

  ngOnInit(): void {
    this.changeUserForm = this.fb.group({
      Name: [this.user.Name],
      Phone: [this.user.Phone],
      Email: [this.user.Email],
      CurQuestion: [this.user.CurQuestion, [Validators.required, Validators.min(1), Validators.max(43)]],
      Attempts: [this.user.Attempts, [Validators.required, Validators.min(0)]],
      Code: [null, [ Validators.minLength(6), Validators.maxLength(6)]],
    })
  }

  get fval() {
    return this.changeUserForm.controls;
  }

  generateCode(len) {
    this.fval.Code.setValue(null);
    var i: number = 0;
    var code: string = "";
    var symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (i=0; i<len; i++) {
      code = code + symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.fval.Code.setValue(code);
  }

  changeUser() {
    this.submitted = true;
    if (this.changeUserForm.invalid) {
      return;
    }
    if (!confirm("Вы уверены, что хотите изменить данные пользователя?")) {
      return;
    }
    const subscription = this.us.ChangeUser(this.changeUserForm.value, this.user.Id).subscribe(
      (response) => {
        if (response) {
          this.alertService.show("Пользователь изменен!", {
            classname: "bg-success text-light unf",
            delay: 5000,
          });
          this.reload = true;
          this.modal.dismiss();
          return true;
        } else {
          this.alertService.show("Пользователь не был создан! Попробуйте ещё раз", {
            classname: "bg-danger text-light unf",
            delay: 5000,
          });
        }
      }
    )
  }
}
