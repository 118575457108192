import { Component, OnInit } from "@angular/core";
import { Translate } from "../models/translate";
import { MainService } from "../services/backend/main.service";
import { QuestionService } from "../services/backend/question.service";
import { LoadService } from "../services/load.service";

@Component({
  selector: "app-conclusions",
  templateUrl: "./conclusions.component.html",
  styleUrls: ["./conclusions.component.less"],
})
export class ConclusionsComponent implements OnInit{
  loading: boolean = true;
  showSoldier = true;
  conclusions: Conclusion[] = [];
  public currentTab = 0;

  constructor(
    private ls: LoadService,
    private ms: MainService,
    private qs: QuestionService
  ) {}

  ngOnInit() {
    this.ls.showLoad = false;
    this.qs.GetConclusions("userdata").subscribe((data) => {
      if (data) {
        this.conclusions = data;
      }
    });
    var vm = this;
    setTimeout(function () {
      vm.showSoldier = false;
    }, 5000);
  }

  public changeTab($event: number) {
    this.currentTab = $event;
    console.log(this.currentTab)
  }
}

export class Conclusion {
  Id: number;
  Conclusion: Translate | string;
  VariantId: number;

  IsShown: boolean = false;
}
