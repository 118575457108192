import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/operators";
import { Conclusion } from "src/app/conclusions/conclusions.component";
import { AlertService } from "src/app/services/alert/alert.service";
import { QuestionService } from "src/app/services/backend/question.service";
import { UserService } from "src/app/services/backend/user.service";
import { Tests } from "../admin-graphics/admin-graphics.component";
import { CreateConclusionComponent } from "./create-conclusion/create-conclusion.component";

@Component({
  selector: "admin-conclusions",
  templateUrl: "./admin-conclusions.component.html",
  styleUrls: ["./admin-conclusions.component.less"],
})
export class AdminConclusionsComponent implements OnInit {
  public conclusions: Conclusion[] = [];
  public searchedConclusions: Conclusion[] = [];
  public searchControl: FormControl = new FormControl();
  public conclControl: FormControl = new FormControl();
  testsList: Tests[] = [];
  userConclusions: Conclusion[] = []
  showConclusions: boolean = false;

  constructor(
    private modalService: NgbModal,
    private questionService: QuestionService,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.questionService.GetConclusionsFull("admindata").subscribe((conclusions) => {
      if (conclusions.length > 0) {
        this.conclusions = conclusions;
        this.searchedConclusions = this.searchControl.value
          ? this.getSearchedConclusions(this.searchControl.value)
          : this.conclusions;
      } else {
        this.alertService.show("Ошибка! Выводы не скачаны", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    });
    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.searchedConclusions = value
          ? this.getSearchedConclusions(value)
          : this.conclusions;
      });
    this.conclControl.valueChanges.subscribe((value) => {
      if (value) {
        this.showConclusionsData(value);
      }
    })
  }

  private getSearchedConclusions(searchString: string) {
    return this.conclusions.filter(
      (conclusion) =>
        `${conclusion.Conclusion['ru'].toUpperCase()}${conclusion.Conclusion['en'].toUpperCase()}`.indexOf(
          searchString.toUpperCase()
        ) > -1
    );
  }

  onCreateClick() {
    this.modalService.open(CreateConclusionComponent, { size: "xl" });
  }

  getUserConclusions() {
    this.showConclusions = !this.showConclusions;
    if (!this.showConclusions) {
      this.ngOnInit();
    }
    if (this.showConclusions) {
      this.userService.GetUsersTestsList().subscribe((data: Tests[]) => {
        if (data) { 
          this.testsList = data;
        } else {
          this.alertService.show("Даннные не скачались!", {
            classname: "bg-danger text-light unf",
            delay: 5000,
          });
        }
      });
    }
  }

  showConclusionsData(data: any[]) {
    this.questionService.GetUserConclusions(data[0], data[1], "userconc").subscribe((data) => {
      if (data) {
        this.userConclusions = data;
      }
    })
  }
}
