import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../services/backend/user.service";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.less"],
})
export class AdminComponent implements OnInit {
  currentMenuItems: menuItems[];
  menuClosed = false;
  items: menuItems[] = [
    {
      name: "Вопросы",
      icon: "fas fa-question",
      url: "questions",
      dropdown: false,
    },
    {
      name: "Пользователи",
      icon: "fas fa-users fa-lg",
      url: "users",
      dropdown: false,
    },
    {
      name: "Результаты",
      icon: "fas fa-outdent",
      dropdown: true,
      children: 
      [
        {
          name: "Данные",
          url: "data",
          icon: "fas fa-database",
        },
        {
          name: "Выводы",
          url: "conclusions",
          icon: "fa fa-list",
        },
        {
          name: "Графики",
          url: "graphics",
          icon: "fa fa-bar-chart",
        },
        /*
        {
          name: "Статистика",
          url: "statistics",
          icon: "fa fa-pie-chart",
        },
        */
      ],
    },
  ];

  constructor(
    private router: Router,
    public us: UserService,
    private auths: AuthService,
    private translateService: TranslateService
  ) {
    translateService.use("ru");
  }

  ngOnInit() {
    this.currentMenuItems = this.items;
  }

  exit() {
    this.auths.exit();
    this.router.navigate([""]);
  }

  goToQuestions() {
    this.router.navigate(["/questions"]);
  }

  closeMenu() {
    this.menuClosed = (!this.menuClosed);
  }
}

export interface menuItems {
  name: string;
  icon?: string;
  url?: string;
  dropdown: boolean;
  children?: lowerMenuItems[];
}

export interface lowerMenuItems {
  name: string;
  url: string;
  icon?: string;
}
