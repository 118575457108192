import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertService } from "src/app/services/alert/alert.service";
import { Group, UserService } from "src/app/services/backend/user.service";
import { User } from "../admin-users.component";

@Component({
  selector: "edit-group",
  templateUrl: "./edit-group.component.html",
  styleUrls: ["./edit-group.component.less"],
})
export class EditGroupComponent implements OnInit {
  @Input() public group: Group;
  @Input() public groups: Group[];
  public groupForm: FormGroup;
  public groupUsers: any[] = [];
  public users: User[] = [];
  @Output() public update: EventEmitter<number> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.groupForm = this.fb.group({
      GroupName: this.group.GroupName,
      GroupDescription: this.group.GroupDescription,
      UserSelect: null,
    });
    if (this.group.Users.length > 0) {
      this.groupUsers = this.group.Users;
    }
  }

  getData() {
    this.userService.GetUsersData().subscribe((data: User[]) => {
      if (data) {
        data.forEach((user) => {
          this.group.Users.forEach((grus) => {
            if (grus.Id == user.Id) {
              data = data.filter((obj) => obj != user);
            }
          });
        });
        this.users = data;
      }
    });
  }

  deleteGroupUser(grid: number, uid: number) {
    if (!confirm("Вы уверены, что хотите удалить пользователя из группы?")) {
      return;
    }
    this.userService.DeleteGroupUser(grid, uid).subscribe((response) => {
      if (response) {
        this.update.emit(0);
        this.getData();
        this.alertService.show("Пользователь удален из группы!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      } else {
        this.alertService.show(
          "Пользователь не был удален из-за ошибки сервера. Попробуйте ещё раз!",
          {
            classname: "bg-danger text-light us-delb",
            delay: 5000,
          }
        );
      }
    });
  }

  deleteGroup() {
    if (!confirm("Вы уверены, что хотите удалить группу?")) {
      return;
    }
    this.userService.DeleteGroup(this.group.Id).subscribe((response) => {
      if (response) {
        this.update.emit(0);
        this.alertService.show("Группа удалена!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    });
  }

  addGroupUsers() {
    if (this.fval.UserSelect == null) {
      return;
    }
    this.userService.AddGroupUser(this.fval.UserSelect.value, this.group.Id).subscribe((response) => {
      if (response) {
        this.update.emit(0);
        this.getData();
        this.alertService.show("Группа добавлена!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    })
  }

  get fval() {
    return this.groupForm.controls;
  }

  saveGroup() {
    if (
      this.fval.GroupName.value != this.group.GroupName ||
      this.fval.GroupDescription.value != this.group.GroupDescription
    ) {
      this.userService
        .SaveGroup(this.groupForm.value, this.group.Id)
        .subscribe((response) => {
          if (response) {
            this.update.emit(0);
            this.alertService.show("Группа сохранена!", {
              classname: "bg-success text-light us-dels",
              delay: 5000,
            });
          }
        });
    } else {
      this.alertService.show("Внесите изменения!", {
        classname: "bg-danger text-light us-dels",
        delay: 5000,
      });
    }
  }
}
