import { Inject, Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { User } from "../../admin/admin-users/admin-users.component";
import { Tests } from "src/app/admin/admin-graphics/admin-graphics.component";

@Injectable()
export class UserService implements OnInit {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  ngOnInit() {}

  //--------User--------//
  GetUser(code: string) {
    return this.http.post(`${this.baseUrl}?Key=get-user`, code);
  }
  GetUserId() {
    return this.http.get(`${this.baseUrl}?Key=get-user-id`);
  }
  GetUserCurQuestion() {
    return this.http.get<number>(`${this.baseUrl}?Key=get-usercurq`);
  }
  ChangeUser(data: User, uid: number) {
    return this.http.post(`${this.baseUrl}?Key=change-user`, [data, uid]);
  }
  CheckUser(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}?Key=check-user`);
  }
  CheckTestAccess() {
    return this.http.get<number>(`${this.baseUrl}?Key=check-testacc`);
  }
  AddUser(data) {
    return this.http.post(`${this.baseUrl}?Key=add-user`, data);
  }
  DeleteUser(uid: number) {
    return this.http.post(`${this.baseUrl}?Key=delete-user`, uid);
  }
  ChangeUserCurQuestion(qid: number) {
    return this.http.post(`${this.baseUrl}?Key=change-usercurq`, qid);
  }
  //--------User Data & Lists--------//
  GetUserData() {
    return this.http.get(`${this.baseUrl}?Key=get-userdata`);
  }
  GetUsersData() {
    return this.http.get<User[]>(`${this.baseUrl}?Key=get-usersdata`);
  }
  GetUsersList() {
    return this.http.get<any[]>(`${this.baseUrl}?Key=get-userslist`);
  }
  GetUsersTestsList() {
    return this.http.get<Tests[]>(`${this.baseUrl}?Key=get-ustestslist`);
  }
  //--------User Groups--------//
  SaveGroup(data, id) {
    return this.http.post(`${this.baseUrl}?Key=save-group`, [data, id]);
  }
  GetGroups() {
    return this.http.get<Group[]>(`${this.baseUrl}?Key=get-groups`);
  }
  AddGroup(data) {
    return this.http.post(`${this.baseUrl}?Key=add-group`, data);
  }
  DeleteGroup(grid: number) {
    return this.http.post(`${this.baseUrl}?Key=delete-group`, grid);
  }
  DeleteGroupUser(grid: number, uid: number) {
    return this.http.post(`${this.baseUrl}?Key=delete-groupuser`, [grid, uid]);
  }
  AddGroupUser(uid, grid) {
    return this.http.post(`${this.baseUrl}?Key=add-groupuser`, [uid, grid]);
  }
}

export class Group {
  Id: number;
  GroupName: string;
  GroupDescription: string;
  CreateDate: Date;

  Users?: User[];
}
