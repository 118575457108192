import { Component, Input, OnInit } from "@angular/core";
import {
  ChartType,
  getPackageForChart,
  ScriptLoaderService,
} from "angular-google-charts";

@Component({
  selector: "app-admin-statistics",
  templateUrl: "./admin-statistics.component.html",
  styleUrls: ["./admin-statistics.component.less"],
})
export class AdminStatisticsComponent implements OnInit {
  public chartTypes = ChartType;
  private readonly chartPackage = getPackageForChart(ChartType.LineChart);
  public myData;
  constructor(private loaderService: ScriptLoaderService) {}

  ngOnInit(): void {
    this.loaderService.loadChartPackages(this.chartPackage).subscribe(() => {
      this.myData = new google.visualization.DataTable();
      this.myData.addColumn('string', 'Element');
      this.myData.addColumn('number', 'Percentage');
      this.myData.addRows([
        ['Nitrogen', 0.78],
        ['Oxygen', 0.21],
        ['Other', 0.01]
      ]);
    });
  }
}
