import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Conclusion } from "src/app/conclusions/conclusions.component";
import { Clusters } from "src/app/models/clusters";
import { Translate } from "src/app/models/translate";
import { AlertService } from "src/app/services/alert/alert.service";
import { QuestionService } from "src/app/services/backend/question.service";
import { AdminConclusionsComponent } from "../admin-conclusions.component";
import { AddVariantComponent } from "./add-variant/add-variant.component";
import { Variant } from "../../../questions/questions.component";

@Component({
  selector: "edit-conclusion",
  templateUrl: "./edit-conclusion.component.html",
  styleUrls: ["./edit-conclusion.component.less"],
})
export class EditConclusionComponent implements OnInit {
  @Input() public conclusion: Conclusion;
  @Input() public conclusions: Conclusion[];
  public conclusionForm: FormGroup;
  public variants: VariantD[] = [];
  public data: any[];
  @Output() public update: EventEmitter<number> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private qs: QuestionService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.qs
      .GetConclusionVariants(this.conclusion.Conclusion['ru'])
      .subscribe((data) => {
        if (data.length > 0) {
          data.forEach((variant) => {
            switch (variant.Situation) {
              case "0": {
                variant.Situation = "Нейтральный";
                break;
              }
              case "1": {
                variant.Situation = "Простой";
                break;
              }
              case "2": {
                variant.Situation = "Сложный";
                break;
              }
            }
          });
          this.variants = data;
        } else {
          this.alertService.show("Ошибка сервера!", {
            classname: "bg-danger text-light us-dels",
            delay: 5000,
          });
        }
      });
    this.conclusionForm = this.fb.group({
      ConclusionRu: this.conclusion.Conclusion['ru'],
      ConclusionEng: this.conclusion.Conclusion['en'],
    });
  }

  deleteVariant(variantId: number, conclusionRu: string) {
    if (!confirm("Вы уверены, что хотите удалить данный вариант для вывода?")) {
      return;
    }
    this.qs
      .DeleteConclusionVariant(variantId, conclusionRu)
      .subscribe((response) => {
        if (response) {
          this.update.emit(this.conclusion.Id);
        } else {
          this.alertService.show("Ошибка сервера!", {
            classname: "bg-danger text-light us-dels",
            delay: 5000,
          });
        }
      });
  }

  removeConclusion() {
    if (!confirm("Вы уверены, что хотите удалить вывод?")) {
      return;
    }
    this.qs
      .DeleteConclusion(this.conclusion.Conclusion['ru'])
      .subscribe((response) => {
        if (response) {
          this.alertService.show("Вывод удален!", {
            classname: "bg-success text-light us-dels",
            delay: 5000,
          });
          this.update.emit();
        } else {
          this.alertService.show("Ошибка сервера!", {
            classname: "bg-danger text-light us-dels",
            delay: 5000,
          });
        }
      });
  }

  addVariant() {
    const modalRef = this.modalService.open(AddVariantComponent, {
      size: "xl",
    });
    modalRef.componentInstance.currVariants = this.variants;
    if (modalRef.result != null) {
      modalRef.result.then((nvariants) => {
        this.qs
          .AddConclusionVariants(
            this.conclusion.Conclusion['ru'],
            this.conclusion.Conclusion['en'],
            nvariants
          )
          .subscribe(() => {
            this.ngOnInit();
          });
      });
    } else {
      return;
    }
  }

  private markInvalid(formGroup: FormGroup) {
    for (const control of Object.values(formGroup.controls)) {
      if (control.invalid) {
        control.markAsDirty();
      }
      if (control instanceof FormArray) {
        for (const ctrl of control.controls as FormGroup[]) {
          this.markInvalid(ctrl);
        }
      }
    }
  }

  public saveConclusion() {
    if (this.conclusionForm.invalid) {
      this.markInvalid(this.conclusionForm);
      return;
    }
    this.qs
      .UpdateConclusion(this.conclusionForm.value, this.conclusion.Conclusion['ru'])
      .subscribe((response) => {
        if (response) {
          this.update.emit(this.conclusion.Id);
        } else {
          this.alertService.show("Ошибка сервера", {
            classname: "bg-danger text-light us-dels",
            delay: 5000,
          });
        }
      });
  }
}

export class VariantD {
  Id: number;
  QuestionRu: string;
  Number: string;
  VariantRu: string;
  VariantEng: string;
  Situation: string;
}
