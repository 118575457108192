import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/operators";
import { Question } from "src/app/questions/questions.component";
import { QuestionService } from "src/app/services/backend/question.service";
import { CreateQuestionComponent } from "./create-question/create-question.component";

@Component({
  selector: "app-admin-questions",
  templateUrl: "./admin-questions.component.html",
  styleUrls: ["./admin-questions.component.less"],
})
export class AdminQuestionsComponent implements OnInit {
  public questions: Question[] = [];
  public searchedQuestions: Question[] = [];
  public searchControl: FormControl = new FormControl();

  constructor(
    private questionsService: QuestionService,
    private modalService: NgbModal,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.questionsService.GetQuestions().subscribe((questions) => {
      this.questions = questions;
      this.searchedQuestions = this.searchControl.value
        ? this.getSearchedQuestions(this.searchControl.value)
        : this.questions;
    });
    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.searchedQuestions = value
          ? this.getSearchedQuestions(value)
          : this.questions;
      });
  }

  private getSearchedQuestions(searchString: string) {
    return this.questions.filter(
      (question) =>
        `${question.QuestionRu.toUpperCase()}${question.QuestionEng.toUpperCase()}`.indexOf(
          searchString.toUpperCase()
        ) > -1
    );
  }

  onCreateClick() {
    const modalRef = this.modalService.open(CreateQuestionComponent);
    modalRef.result
      .then((questionRu) => {
        const question = {
          Number: this.questions.length,
          Cluster: "",
          Type: 0,
          QuestionEng: "",
          QuestionRu: questionRu,
          Image: "",
          NextQuestionId: 0,
          LastQuestion: false,
        };
        this.questionsService.AddQuestion(question).subscribe(() => {
          this.ngOnInit();
        });
      })
      .catch(() => {});
  }
}
