import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Answer, Participant, Question, Variant } from "../../questions/questions.component";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { Conclusion } from "src/app/conclusions/conclusions.component";
import { AnswerData } from "src/app/admin/admin-data/admin-data.component";
import { VariantD } from "src/app/admin/admin-conclusions/edit-conclusion/edit-conclusion.component";

@Injectable()
export class QuestionService implements OnInit {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  ngOnInit() {}

  //--------Questions & Variants--------//
  GetQuestions(questionId?) {
    return this.http.get<Question[]>(`${this.baseUrl}?Key=get-questions&qid=${questionId}`);
  }
  AddQuestion(question) {
    return this.http.post<number>(`${this.baseUrl}?Key=add-question`, question);
  }
  UpdateQuestion(question) {
    return this.http.post<boolean>(`${this.baseUrl}?Key=update-question`, question);
  }
  DeleteQuestion(questionId) {
    return this.http.delete<boolean | Question[]>(`${this.baseUrl}?Key=delete-question&qid=${questionId}`);
  }
  UploadQuestionImg(data): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}?Key=upload-question-img`, data);
  }
  ChangeCurQuestion(qid: number) {
    return this.http.post(`${this.baseUrl}?Key=change-curq`, qid);
  }
  GetQuestionVariants(questionId) {
    return this.http.get<Variant[]>(`${this.baseUrl}?Key=get-questionvars&qid=${questionId}`);
  }
  GetVariantById(variantId) {
    return this.http.get<any>(`${this.baseUrl}?Key=get-variantbyid&vid=${variantId}`);
  }
  //--------Answers & Participants--------//
  SaveAnswers(answers: Answer[], type?: number) {
    return this.http.post(`${this.baseUrl}?Key=save-answers`, [{ Answers: answers }, type]);
  }
  GetAnswersData() {
    return this.http.get<AnswerData[]>(`${this.baseUrl}?Key=get-answersdata`);
  }
  GetSelectedAnswersData(pickedData) {
    return this.http.get<AnswerData[]>(`${this.baseUrl}?Key=get-selected-answersdata&pdata=${pickedData}`);
  }
  DeleteAnswer(answerId) {
    return this.http.delete<boolean>(`${this.baseUrl}?Key=delete-answer&id=${answerId}`);
  }
  SaveParticipant(p) {
    return this.http.post<number>(`${this.baseUrl}?Key=save-participant`, p);
  }
  GetParticipantById(participantId) {
    return this.http.get<Participant>(`${this.baseUrl}?Key=get-participantbyid&pid=${participantId}`);
  }
  GetAttempts(uid) {
    return this.http.get<any[]>(`${this.baseUrl}?Key=get-attempts&uid=${uid}`);
  }
  //--------Conclusions--------//
  GetConclusions(type: string) {
    return this.http.get<Conclusion[]>(`${this.baseUrl}?Key=get-conclusions&type=${type}`);
  }
  GetConclusionsFull(type: string) {
    return this.http.get<Conclusion[]>(`${this.baseUrl}?Key=get-conclusions&type=${type}`);
  }
  GetUserConclusions(userId: number, attempt: number, type: string) {
    return this.http.get<Conclusion[]>(`${this.baseUrl}?Key=get-userconclusions&uid=${userId}&attempt=${attempt}&type=${type}`);
  }
  GetConclusionVariants(conclusion : string) {
    return this.http.get<VariantD[]>(`${this.baseUrl}?Key=get-conclvariants&conclusion=${conclusion}`);
  }
  AddConclusion(conclusion, variants) {
    return this.http.post<number>(`${this.baseUrl}?Key=add-conclusion`, [conclusion, variants]);
  }
  AddConclusionVariants(conclRu:string, conclEng:string, variants:Variant[]) {
    return this.http.post<number>(`${this.baseUrl}?Key=add-conclvariants`, [conclRu, conclEng, variants]);
  }
  UpdateConclusion(conclusion: Conclusion, concRu: string) {
    return this.http.post<boolean>(`${this.baseUrl}?Key=update-conclusion`, [conclusion, concRu]);
  }
  DeleteConclusion(conclusion : string) {
    return this.http.delete<boolean>(`${this.baseUrl}?Key=delete-conclusion&conclusion=${conclusion}`);
  }
  DeleteConclusionVariant(variantId : number, conclusionRu : string) {
    return this.http.delete<boolean>(`${this.baseUrl}?Key=delete-conclvariant&varid=${variantId}&concru=${conclusionRu}`);
  }
  //--------Graphs--------//
  GetClusterAnswers(uid: number, attempt: number) {
    return this.http.get<any[]>(`${this.baseUrl}?Key=get-clusteranswers&uid=${uid}&attempt=${attempt}`);
  }
  GetSituationAnswers(uid: number, attempt: number) {
    return this.http.get<any[]>(`${this.baseUrl}?Key=get-situation-answers&uid=${uid}&attempt=${attempt}`);
  }
  GetAnswersSpeed(uid: number, attempt: number) {
    return this.http.get<any[]>(`${this.baseUrl}?Key=get-answers-speed&uid=${uid}&attempt=${attempt}`);
  }
}
