import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './backend/user.service';
 
@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {

    constructor(private router: Router, private us: UserService) {
        
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let token = sessionStorage.getItem('userToken');
        if (token) {
          return this.us.CheckUser().pipe(
            tap((result) => {
              if (!result) {
                this.router.navigate([''], { queryParams: { returnUrl: state.url }});
              }
            })
          );
        }
        this.router.navigate([''], { queryParams: { returnUrl: state.url }});
        return false;
      }
}