import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
  alerts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.alerts.push({ textOrTpl, ...options });
  }

  remove(alert) {
    this.alerts = this.alerts.filter(t => t !== alert);
  }
}