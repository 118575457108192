import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/services/backend/user.service";
import { AlertService } from "src/app/services/alert/alert.service";

@Component({
  selector: "user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.less"],
})
export class UserAddComponent implements OnInit {
  addUserForm: FormGroup;
  submitted = false;
  sendInv = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private us: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.addUserForm = this.fb.group({
      Name: [null],
      Phone: [null],
      Email: [null, Validators.email],
      CurQuestion: [
        1,
        [Validators.required, Validators.min(1), Validators.max(43)],
      ],
      Attempts: [1, [Validators.required, Validators.min(0)]],
      SendInv: [null],
      Code: [
        null,
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
    });
    this.generateCode(6);
  }

  get fval() {
    return this.addUserForm.controls;
  }

  generateCode(len) {
    this.fval.Code.setValue(null);
    var i: number = 0;
    var code: string = "";
    var symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (i = 0; i < len; i++) {
      code = code + symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.fval.Code.setValue(code);
  }

  createUser() {
    if (this.fval.SendInv.value == false) {
      this.fval.Email.setValidators(Validators.email);
      this.fval.Email.updateValueAndValidity();
    }
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return;
    }
    if (this.fval.SendInv.value == true && this.fval.Email.value == null) {
      this.sendInv = true;
      this.fval.Email.setValidators([Validators.required, Validators.email]);
      this.fval.Email.updateValueAndValidity();
      return;
    }
    const subscription = this.us.AddUser(this.addUserForm.value).subscribe(
      (response) => {
        if (response) {
          this.alertService.show("Пользователь создан!", {
            classname: "bg-success text-light unf",
            delay: 5000,
          });
          this.modal.dismiss();
        } else {
          this.alertService.show("Пользователь не был создан! Попробуйте ещё раз", {
            classname: "bg-danger text-light unf",
            delay: 5000,
          });
        }
      }
    )
  }
}
