import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { element } from "protractor";
import { stringify } from "querystring";
import { debounceTime } from "rxjs/operators";
import { AlertService } from "src/app/services/alert/alert.service";
import { QuestionService } from "src/app/services/backend/question.service";
import { Group, UserService } from "src/app/services/backend/user.service";
import { Tests } from "../admin-graphics/admin-graphics.component";
import { ChangeAnswerComponent } from "./change-answer/change-answer.component";

@Component({
  selector: "app-admin-data",
  templateUrl: "./admin-data.component.html",
  styleUrls: ["./admin-data.component.less"],
})
export class AdminDataComponent implements OnInit {
  public data: AnswerData[];
  public searchControlName: FormControl = new FormControl();
  public searchedData: AnswerData[] = [];
  pickedData: PickedData[] = [];
  dataPickForm: FormGroup;
  tests: Tests[] = [];
  groups: Group[] = [];
  fixHeader: boolean = false;
  dataPickerClosed: boolean = false;
  showPickedTable: boolean = false;
  submitted: boolean = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private questionService: QuestionService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { }

  @HostListener("document:scroll", [])
  onScroll(): void {
    if (window.pageYOffset > 100) {
      this.fixHeader = true;
    } else {
      this.fixHeader = false;
    }
  }

  togglePicker() {
    this.dataPickerClosed = !this.dataPickerClosed;
  }

  ngOnInit(): void {
    this.getData();
    this.dataPickForm = this.fb.group({
      DataUserPick: [null],
      DataGroupPick: [null],
    });
  }

  private getSearchedData(searchString: string) {
    if (this.data) {
      return this.data.filter(
        (array) =>
          `${array.Name.toUpperCase()}${array.QuestionRu.toUpperCase()}${array.Answer
            }${array.Date}`.indexOf(searchString.toUpperCase()) > -1
      );
    } else {
      return;
    }
  }

  get fval() {
    return this.dataPickForm.controls;
  }

  selectedCheck(data) {
    if (this.pickedData.indexOf(data) > -1) {
      return false;
    } else {
      return true;
    }
  }

  showAllData() {
    this.questionService.GetAnswersData().subscribe((data) => {
      if (data) {
        this.data = data;
        this.data.forEach((element) => {
          switch (element.Situation) {
            case "0": {
              element.Situation = "Нейтральный";
              break;
            }
            case "1": {
              element.Situation = "Простой";
              break;
            }
            case "2": {
              element.Situation = "Сложный";
              break;
            }
            default: {
              element.Situation = "-";
              break;
            }
          }
          element.VariantRu
            ? (element.Answer = element.VariantRu)
            : element.VariantRu;
          if (element.PartName == null) {
            element.PartName = "-";
          }
        });
        this.searchedData = this.searchControlName.value
          ? this.getSearchedData(this.searchControlName.value)
          : this.data;
      }
    });
    this.dataPickerClosed = true;
  }

  showData() {
    this.submitted = true;
    this.pickedData.forEach((element) => delete element[1]);
    this.questionService
      .GetSelectedAnswersData(this.pickedData)
      .subscribe((data) => {
        if (data) {
          this.data = data;
          this.data.forEach((element) => {
            switch (element.Situation) {
              case "0": {
                element.Situation = "Нейтральный";
                break;
              }
              case "1": {
                element.Situation = "Простой";
                break;
              }
              case "2": {
                element.Situation = "Сложный";
                break;
              }
              default: {
                element.Situation = "-";
                break;
              }
            }
            element.VariantRu
              ? (element.Answer = element.VariantRu)
              : element.VariantRu;
            if (element.PartName == null) {
              element.PartName = "-";
            }
          });
          this.searchedData = this.searchControlName.value
            ? this.getSearchedData(this.searchControlName.value)
            : this.data;
        }
      });
  }

  getData() {
    /*
    this.userService.GetUsersTestsList().subscribe((data: any[]) => {
      if (data) {
        data.forEach((element) => {
          if (element.User["Name"] == null) {
            element.User["Name"] = "-";
          }
        });
        this.tests = data;
      }
    });
    this.userService.GetGroups().subscribe((data: Group[]) => {
      if (data) {
        this.groups = data;
      }
    });
    
    */
    this.searchControlName.valueChanges
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.searchedData = value ? this.getSearchedData(value) : this.data;
      });
    this.showAllData();
  }

  addData() {
    if (
      this.fval.DataGroupPick.value != null ||
      this.fval.DataUserPick != null
    ) {
      if (
        this.fval.DataGroupPick.value != null &&
        this.fval.DataUserPick.value != null
      ) {
        if (this.pickedData.indexOf(this.fval.DataGroupPick.value) == -1) {
          this.pickedData.push(this.fval.DataGroupPick.value);
        }
        if (this.pickedData.indexOf(this.fval.DataUserPick.value) == -1) {
          this.pickedData.push(this.fval.DataUserPick.value);
        }
        this.showPickedTable = true;
        this.dataPickForm.reset();
        return;
      }
      if (this.fval.DataGroupPick.value != null) {
        if (this.pickedData.indexOf(this.fval.DataGroupPick.value) == -1) {
          this.pickedData.push(this.fval.DataGroupPick.value);
        }
        this.showPickedTable = true;
        this.fval.DataGroupPick.reset();
        return;
      } else {
        if (this.pickedData.indexOf(this.fval.DataUserPick.value) == -1) {
          this.pickedData.push(this.fval.DataUserPick.value);
        }
        this.showPickedTable = true;
        this.fval.DataUserPick.reset();
      }
    } else {
      return;
    }
  }

  deletePickedData(data, type) {
    let change = this.pickedData;
    change.forEach((element) => {
      if (element[1] == data && element[2] == type) {
        delete change[change.indexOf(element)];
      }
    });
    this.pickedData = change;
    if (this.pickedData.length == 0) {
      this.showPickedTable = false;
    }
  }

  changeAnswer(answer: AnswerData) {
    let modal = this.modalService.open(ChangeAnswerComponent, { size: "lg" });
    modal.componentInstance.answer = answer;
  }

  deleteAnswer(aid: number) {
    if (!confirm("Вы уверены, что хотите удалить пользователя?")) {
      return;
    }
    this.questionService.DeleteAnswer(aid).subscribe((response) => {
      if (response) {
        this.getData();
        this.alertService.show("Ответ удален!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    });
  }
}

export class AnswerData {
  Id: number;
  Name: string;
  Attempt: number;
  QuestionId: string;
  Type: number;
  QuestionRu: string;
  Answer: string;
  VariantRu: string;
  Situation: string;
  PartName: string;
  Speed: number;
  Date: Date;
}

export class PickedData {
  Id: number;
  Data: string;
  Attempt?: number;
  Type: string;
}
