import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Translate } from "src/app/models/translate";
import { AlertService } from "src/app/services/alert/alert.service";
import { QuestionService } from "src/app/services/backend/question.service";
import { UserService } from "src/app/services/backend/user.service";
import { LoadService } from "src/app/services/load.service";

@Component({
  selector: "admin-graphics",
  templateUrl: "./admin-graphics.component.html",
  styleUrls: ["./admin-graphics.component.less"],
})
export class AdminGraphicsComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  graphForm: FormGroup;
  testsList: Tests[] = [];
  view: any[];
  single: any[];
  multi: any[];
  //------Переменные графиков------
  isVertStack = false;
  isVert = false;
  isLine = false;
  //------Настройки графиков------
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  showLegend: boolean = true;
  gradient: boolean = false;

  xAxis: boolean = true;
  yAxis: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string;
  yAxisLabel: string;
  timeline: boolean = true;

  colorScheme = {
    domain: ["#5AA454", "#E44D25", "#CFC0BB", "#7aa3e5", "#a8385d", "#aae3f5"],
  };

  constructor(
    private fb: FormBuilder,
    private us: UserService,
    private qs: QuestionService,
    private alertService: AlertService,
    private ls: LoadService
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.us.GetUsersTestsList().subscribe((data: Tests[]) => {
      if (data) {
        this.testsList = data;
        this.testsList.forEach((t: Tests) => {
          if (t.Date) {
            t.Date = new Date(t.Date.toString().replace(/ /g, "T"));
          }
        });
      } else {
        this.alertService.show("Даннные не скачались!", {
          classname: "bg-danger text-light unf",
          delay: 5000,
        });
      }
    });
    this.graphForm = this.fb.group({
      Data: [null, Validators.required],
      Graph: [null, Validators.required],
      Language: [null, Validators.required],
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1440) {
      if (this.screenWidth <= 1330) {
        this.view = [1080, 550];
      } else {
        this.view = [1200, 600];
      }
    }
  }

  get fval() {
    return this.graphForm.controls;
  }

  onSelect(data): void {
    //console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }

  generateGraph(data) {
    if (this.graphForm.invalid) {
      return;
    }
    this.ls.showLoad = true;
    data = this.fval.Data.value;
    this.isVertStack = false;
    this.isVert = false;
    this.isLine = false;

    switch (this.fval.Graph.value) {
      case "График кластеров": {
        switch (this.fval.Language.value) {
          case "Русский (RU)": {
            this.xAxisLabel = "Кластер";
            this.yAxisLabel = "Количество ответов";
            break;
          }
          case "English (EN)": {
            this.xAxisLabel = "Cluster";
            this.yAxisLabel = "Number of answers";
            break;
          }
        }
        this.view = [1400, 600];
        if (window.innerWidth <= 1440) {
          if (this.screenWidth <= 1370) {
            this.view = [1080, 500];
          } else {
            this.view = [1200, 580];
          }
        }
        if (window.innerWidth <= 1200) {
          this.view = [920, 510];
        }
        this.colorScheme = {
          domain: ["#bbbbbb", "#5AA454", "#C80000", "#bbbbbb"],
        };
        this.qs
          .GetClusterAnswers(data[0], data[1])
          .subscribe((data: { Situation: string; Cluster: string }[]) => {
            const situations = [...new Set(data.map((d) => d.Situation))].sort(
              (a, b) => +a - +b
            );
            let mapped = data.map(
              (element: { Situation: string; Cluster: string }) => {
                return {
                  name: element.Cluster,
                  series: situations.map((situation) => {
                    return {
                      name: this.getSituationTitle(situation),
                      value: data.filter(
                        (d) =>
                          d.Cluster == element.Cluster &&
                          d.Situation == situation
                      ).length,
                    };
                  }),
                };
              }
            );
            this.multi = mapped;
            this.ls.showLoad = false;
            this.isVertStack = true;
          });
        break;
      }
      case "График средней скорости": {
        switch (this.fval.Language.value) {
          case "Русский (RU)": {
            this.xAxisLabel = "Вопрос";
            this.yAxisLabel = "Скорость ответа, с";
            break;
          }
          case "English (EN)": {
            this.xAxisLabel = "Question";
            this.yAxisLabel = "Answer speed, s";
            break;
          }
        }
        this.view = [1400, 700];
        if (window.innerWidth <= 1440) {
          if (this.screenWidth <= 1370) {
            this.view = [1080, 550];
          } else {
            this.view = [1200, 640];
          }
        }
        if (window.innerWidth <= 1200) {
          this.view = [1015, 560];
        }
        this.colorScheme = {
          domain: ["#0000ff"],
        };
        this.qs.GetAnswersSpeed(data[0], data[1]).subscribe(
          (
            data: {
              Name: string;
              Number: string;
              Question: Translate;
              Speed: number;
            }[]
          ) => {
            let mapped = [
              {
                name:
                  this.fval.Language.value == "English (EN)"
                    ? "Speed"
                    : "Скорость",
                series: data.map((d) => {
                  return {
                    name:
                      this.fval.Language.value == "English (EN)"
                        ? d.Question.en
                        : d.Question.ru,
                    value: (d.Speed / 1000).toFixed(1) || 0,
                  };
                }),
              },
            ];
            this.multi = mapped;
            this.ls.showLoad = false;
            this.isLine = true;
          }
        );
        break;
      }
      case "График ответов": {
        switch (this.fval.Language.value) {
          case "Русский (RU)": {
            this.xAxisLabel = "Вопрос";
            this.yAxisLabel = "Номер ответа";
            break;
          }
          case "English (EN)": {
            this.xAxisLabel = "Question";
            this.yAxisLabel = "Answer number";
            break;
          }
        }
        this.view = [1400, 700];
        if (window.innerWidth <= 1440) {
          if (this.screenWidth <= 1370) {
            this.view = [1080, 630];
          } else {
          this.view = [1200, 640];
          }
        }
        if (window.innerWidth <= 1200) {
          this.view = [1015, 560];
        }
        this.colorScheme = {
          domain: ["#008000", "#ff0000", "#0000ff", "#bbbbbb"],
        };
        this.qs.GetSituationAnswers(data[0], data[1]).subscribe(
          (
            data: {
              BadNumber: number;
              GoodNumber: number;
              Number: number;
              QuestionEng: string;
              QuestionRu: string;
              UserName: string;
            }[]
          ) => {
            let mapped = [
              {
                name:
                  this.fval.Language.value == "English (EN)"
                    ? "Easy"
                    : "Легкий",
                series: data.map((d) => {
                  return {
                    name:
                      this.fval.Language.value == "English (EN)"
                        ? d.QuestionEng
                        : d.QuestionRu,
                    value: d.GoodNumber || 0,
                  };
                }),
              },
              {
                name:
                  this.fval.Language.value == "English (EN)"
                    ? "Complicated"
                    : "Сложный",
                series: data.map((d) => {
                  return {
                    name:
                      this.fval.Language.value == "English (EN)"
                        ? d.QuestionEng
                        : d.QuestionRu,
                    value: d.BadNumber || 0,
                  };
                }),
              },
              {
                name:
                  data[0].UserName == null
                    ? this.fval.Language.value == "English (EN)"
                      ? "User"
                      : "Пользователь"
                    : data[0].UserName,
                series: data.map((d) => {
                  return {
                    name:
                      this.fval.Language.value == "English (EN)"
                        ? d.QuestionEng
                        : d.QuestionRu,
                    value: d.Number || 0,
                  };
                }),
              },
            ];
            this.multi = mapped;
            this.ls.showLoad = false;
            this.isLine = true;
          }
        );
        break;
      }
    }
  }

  getSituationTitle(situation: string) {
    switch (situation) {
      case "0": {
        if (this.fval.Language.value == "English (EN)") {
          return "Neutral";
        }

        return "Нейтральный";
      }
      case "1": {
        if (this.fval.Language.value == "English (EN)") {
          return "Easy";
        }

        return "Легкий";
      }
      case "2": {
        if (this.fval.Language.value == "English (EN)") {
          return "Complicated";
        }

        return "Сложный";
      }
    }
  }
}

export class Tests {
  UserId: number;
  User: string[];
  Attempt: number;
  Date: Date;
}
