import { Component, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Question } from "src/app/questions/questions.component";
import { AlertService } from "src/app/services/alert/alert.service";
import { Group, UserService } from "src/app/services/backend/user.service";
import { GroupAddComponent } from "./group-add/group-add.component";
import { UserAddComponent } from "./user-add/user-add.component";
import { UserChangeComponent } from "./userchange/userchange.component";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.less"],
})
export class AdminUsersComponent implements OnInit {
  users: User[] = [];
  groups: Group[] = [];
  dataHeaders: any[];
  groupForm: FormGroup;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private modalServie: NgbModal,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.getData();
    this.groupForm = this.fb.group({
      GroupName: [null],
      GroupDescription: [null],
    });
  }

  getData() {
    this.userService.GetUsersData().subscribe((data: User[]) => {
      if (data) {
        this.users = data;
        this.users.forEach((u: User) => {
          if (u.RegDate) {
            u.RegDate = new Date(u.RegDate.toString().replace(/ /g, "T"));
          }
        });
      }
    });
    this.userService.GetGroups().subscribe((data: Group[]) => {
      if (data) {
        this.groups = data;
      }
    })
  }

  createUser() {
    this.modalServie.open(UserAddComponent, { size: "lg" });
  }

  createGroup() {
    this.modalServie.open(GroupAddComponent, { size: "lg" });
  }

  changeUser(uid: number) {
    let modal = this.modalServie.open(UserChangeComponent, { size: "lg" });
    this.users.forEach((user) => {
      if (user.Id == uid) {
        modal.componentInstance.user = user;
      }
    });
  }

  deleteUser(uid: number) {
    if (!confirm("Вы уверены, что хотите удалить пользователя?")) {
      return;
    }
    this.userService.DeleteUser(uid).subscribe((response) => {
      if (response) {
        this.getData();
        this.alertService.show("Пользователь удален!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      } else {
        this.alertService.show(
          "Пользователь не был удален из-за ошибки сервера. Попробуйте ещё раз!",
          {
            classname: "bg-danger text-light us-delb",
            delay: 5000,
          }
        );
      }
    });
  }

  deleteGroupUser(grid: number, uid: number) {
    if (!confirm("Вы уверены, что хотите удалить пользователя из группы?")) {
      return;
    }
    this.userService.DeleteGroupUser(grid, uid).subscribe((response) => {
      if (response) {
        this.getData();
        this.alertService.show("Пользователь удален из группы!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      } else {
        this.alertService.show(
          "Пользователь не был удален из-за ошибки сервера. Попробуйте ещё раз!",
          {
            classname: "bg-danger text-light us-delb",
            delay: 5000,
          }
        );
      }
    })
  }

  deleteGroup(grid: number) {
    if (!confirm("Вы уверены, что хотите удалить группу?")) {
      return;
    };
    this.userService.DeleteGroup(grid).subscribe((response) => {
      if (response) {
        this.getData();
        this.alertService.show("Группа удалена!", {
          classname: "bg-success text-light us-dels",
          delay: 5000,
        });
      }
    })
  }
}

export class User {
  Id: number;
  Code: string;
  Email?: string;
  Phone?: number | string;
  Name?: string;
  CurQuestion: Question;
  Attempts: number;
  RegDate: Date;
  IsAdmin: boolean | string;
}
