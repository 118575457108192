import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { Clusters } from "src/app/models/clusters";
import { Question, Variant } from "src/app/questions/questions.component";
import { QuestionService } from "src/app/services/backend/question.service";

@Component({
  selector: "app-edit-question",
  templateUrl: "./edit-question.component.html",
  styleUrls: ["./edit-question.component.css"],
})
export class EditQuestionComponent implements OnInit {
  @Input() public question: Question;
  @Input() public questions: Question[];
  public questionForm: FormGroup;
  public clusters: string[] = [];
  public alerts: Question[] = [];
  @Output() public update: EventEmitter<number> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private questionService: QuestionService
  ) {
    Object.keys(Clusters).forEach((key) => {
      this.clusters.push(key);
    });
  }

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      Id: this.question.Id,
      Number: this.question.Number,
      Cluster: this.question.Cluster,
      Type: this.question.Type,
      QuestionEng: this.question.QuestionEng,
      QuestionRu: this.question.QuestionRu,
      Image: this.question.Image,
      NextQuestionId: this.question.NextQuestionId,
      LastQuestion: !!this.question.LastQuestion,

      Variants: this.getVariantsVormArray(),
    });
    const nextQuestionControl = this.questionForm.get("NextQuestionId");
    this.questionForm.get("LastQuestion").valueChanges.subscribe((value) => {
      value ? nextQuestionControl.disable() : nextQuestionControl.enable();
    });

    const variantsControl = this.questionForm.get("Variants");
    this.questionForm.get("Type").valueChanges.subscribe((value) => {
      value == 1 ? variantsControl.disable() : variantsControl.enable();
    });
  }

  getVariantsVormArray(): FormArray {
    if (!this.question.Variants?.length) {
      return this.fb.array([]);
    }
    const array = this.fb.array([]);
    this.question.Variants.forEach((variant: Variant) => {
      array.push(this.getVariantForm(variant));
    });

    return array;
  }

  getVariantForm(variant: Variant): FormGroup {
    const fromGroup = this.fb.group({
      Id: variant.Id,
      VariantRu: [variant.VariantRu, Validators.required],
      VariantEng: [variant.VariantEng, Validators.required],
      Situation: [variant.Situation, Validators.required],
      Number: [variant.Number, Validators.required],
      NextQuestionId: [variant.NextQuestionId, Validators.required],
      QuestionId: this.question.Id,
    });
    return fromGroup;
  }

  onAddVariantClick() {
    const variants = this.questionForm.get("Variants") as FormArray;
    variants.push(
      this.getVariantForm({ VariantRu: "Вариант ответа" } as Variant)
    );
  }

  onRemoveVariantClick(index: number) {
    if (!confirm("Вы уверены, что хотите удалить вариант ответа?")) {
      return;
    }
    const variants = this.questionForm.get("Variants") as FormArray;
    variants.removeAt(index);
  }

  private markInvalid(formGroup: FormGroup) {
    for (const control of Object.values(formGroup.controls)) {
      if (control.invalid) {
        control.markAsDirty();
      }
      if (control instanceof FormArray) {
        for (const ctrl of control.controls as FormGroup[]) {
          this.markInvalid(ctrl);
        }
      }
    }
  }

  public removeQuestion() {
    if (!confirm("Вы уверены, что хотите удалить вопрос?")) {
      return;
    }
    this.questionService
      .DeleteQuestion(this.question.Id)
      .subscribe((result) => {
        if (Array.isArray(result)) {
          this.alerts = result;
          return;
        }
        this.alerts.splice(0);
        this.update.emit(0);
      });
  }

  public saveQuestion() {
    if (this.questionForm.invalid) {
      this.markInvalid(this.questionForm);
      return;
    }
    const question = this.questionForm.getRawValue();
    this.uploadImg(question.Image).subscribe((data) => {
      question.Image = data;
      question.oldImg = this.question.Image;
      this.questionService.UpdateQuestion(question).subscribe(() => {
        this.update.emit(question.id);
      });
    });
  }

  uploadImg(Image): Observable<string> {
    if (Image instanceof File) {
      const formData = new FormData();
      formData.append("Image", Image, Image.name.replace(" ", "_"));
      return this.questionService.UploadQuestionImg(formData);
    } else {
      return of(Image);
    }
  }

  isUploadFileShown() {
    const value = this.questionForm.get("Image")?.value;

    return !value || value instanceof File;
  }

  removeImg() {
    this.questionForm.get("Image").setValue(null);
  }

  get variants(): FormGroup[] {
    return (this.questionForm.get("Variants") as FormArray)
      .controls as FormGroup[];
  }
}
