import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Question } from 'src/app/questions/questions.component';
import { QuestionService } from 'src/app/services/backend/question.service';
import { VariantD } from '../edit-conclusion.component';

@Component({
  selector: 'app-add-variant',
  templateUrl: './add-variant.component.html',
  styleUrls: ['./add-variant.component.less']
})
export class AddVariantComponent implements OnInit {
  @Input() currVariants: VariantD[];
  questionControl: FormControl = new FormControl;
  variantControl: FormControl = new FormControl;
  questions: Question[] = [];
  variants: VariantD[] = [];
  pickedVariants: VariantD[] = [];
  sumbitted: boolean = false;
  repeatedVar: boolean = false;
  repeatedVarNames: string;

  constructor(public modal: NgbActiveModal, public questionService: QuestionService) { }

  ngOnInit(): void {
    this.questionService.GetQuestions().subscribe((data) => {
      if (data) {
        data.forEach(element => {
          if ((element.Type != 0) || (element.Variants.length == 0)) {
            data = data.filter(obj => obj !== element);
          }
        });
        this.questions = data;
      }
    });
    this.questionControl.valueChanges.subscribe((value) => {
      this.variants = value.Variants;
    });
  }

  addVariant() {
    this.repeatedVar = false;
    this.sumbitted = true;
    if (this.variantControl.value != null) {
      if (this.pickedVariants.indexOf(this.variantControl.value) == -1) {
        this.currVariants.forEach(element => {
          if (element.Id == this.variantControl.value.Id) {
            this.repeatedVar = true;
            return;
          } 
        });
        if (!this.repeatedVar) {
          this.pickedVariants = [...this.pickedVariants, this.variantControl.value];
          return;
        }
      } else {
        this.repeatedVar = true;
        return;
      }
    } else {
      return;
    }
  }

  addVariants() {
    if (this.pickedVariants.length > 0) {
      this.modal.close(this.pickedVariants);
    } else {
      return;
    }
  }

  deleteVariant(variant: VariantD) {
    this.pickedVariants = this.pickedVariants.filter(obj => obj !== variant);
  }
}
