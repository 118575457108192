import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../services/backend/main.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {
  feedbackForm: FormGroup;
  constructor(private fb: FormBuilder, private service: MainService) {
    this.feedbackForm = this.fb.group({
      General: [null, Validators.required],
      Accuracy: [null, Validators.required],
      Feelings: [null],
      Repeat: [null],
      Friends: [null],
      Emails: [null]
    })
   }

  ngOnInit(): void {
  }

  get feedBack(){
    return this.feedbackForm.controls;
  }

  save(){
    if(this.feedbackForm.invalid){
      Object.keys(this.feedbackForm.controls).forEach(k => {
        this.feedbackForm.markAllAsTouched();
      })
      return;
    }
    this.service.SendFeedback(this.feedbackForm.value).subscribe(
      (response) => {
        if (response) {
          alert('Thank you! Your feedback is saved. We will contact you soon and send your results.');
        } else {
          alert('You feedback was not saved due to server issue. Please, contact us on email: info@ini-consulting.com');
        }
    })

  }

}
