import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Question } from "src/app/questions/questions.component";
import { QuestionService } from "src/app/services/backend/question.service";
import { VariantD } from "../edit-conclusion/edit-conclusion.component";

@Component({
  selector: "app-create-conclusion",
  templateUrl: "./create-conclusion.component.html",
  styleUrls: ["./create-conclusion.component.less"],
})
export class CreateConclusionComponent implements OnInit {
  addConclusionForm: FormGroup;
  questionControl: FormControl = new FormControl;
  variantControl: FormControl = new FormControl;
  variants: VariantD[] = [];
  pickedVariants: VariantD[] = [];
  questions: Question[] = [];
  submittedConc: boolean = false;
  submittedVar: boolean = false;
  repeatedVar: boolean = false;

  constructor(public modal: NgbActiveModal, public fb: FormBuilder, private questionService: QuestionService) {}

  ngOnInit(): void {
    this.questionService.GetQuestions().subscribe((data) => {
      if (data) {
        data.forEach(element => {
          if ((element.Type != 0) || (element.Variants.length == 0)) {
            data = data.filter(obj => obj !== element);
          }
        });
        this.questions = data;
      }
    });
    this.addConclusionForm = this.fb.group({
      ConclusionRu: [null, Validators.required],
      ConclusionEng: [null, Validators.required],
    });
    this.questionControl.valueChanges.subscribe((value) => {
      this.variants = value.Variants;
    });
  }

  get fval() {
    return this.addConclusionForm.controls;
  }

  addConclusion(): void {
    this.submittedConc = true;
    if (this.addConclusionForm.invalid) {
      return;
    }
    this.questionService.AddConclusion(this.addConclusionForm.value, this.pickedVariants).subscribe((response) => {
      if (response) {
        this.modal.dismiss();
      }
    })
  }

  addVariant() {
    this.repeatedVar = false;
    this.submittedVar = true;
    if (this.variantControl.value != null) {
      if (this.pickedVariants.indexOf(this.variantControl.value) == -1) {
        this.pickedVariants = [...this.pickedVariants, this.variantControl.value];
      } else {
        this.repeatedVar = true;
        return;
      }
    } else {
      return;
    }
  }

  deleteVariant(variant: VariantD) {
    this.pickedVariants = this.pickedVariants.filter(obj => obj !== variant);
  }
}
