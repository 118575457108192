import { Inject, Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Question, Variant } from "../../questions/questions.component";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class MainService implements OnInit {
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {}
  ngOnInit() {}

  SendMessage(message: Message) {
    return this.http.post<boolean>(`${this.baseUrl}?Key=send-message`, message);
  }
  SendFeedback(message) {
    return this.http.post<boolean>(
      `${this.baseUrl}?Key=send-feedback`,
      message
    );
  }
  SendData(info): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}?Key=send-info`, info);
  }
}

export class User {
  token: string;
  tests: number;
}

export class Message {
  name: string;
  email: string;
  message: string;
}
